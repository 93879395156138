import {getTicks} from '../../../helper/uiFunctions';
import hotkeys from 'hotkeys-js';

//this will help generate a unique key for forms on screen
var formIdCounter = 0;

//this will be incremented for every formdialog popup displayed
//to be used by hotkeys to enable the hotkeys for top most layer while disabling for those behind it
//eg: when a formdialog is displayed short keys of only that formdialog should work
var layerCounter = 0;

const getCurrentLayer = function(){
    //ensure the default scope shifts to the first one if it is not
    const currentScope = hotkeys.getScope();

    if(!layerCounter || layerCounter < 0) layerCounter = 0;

    if(currentScope === 'all'){
        hotkeys.setScope(`scope_${layerCounter}`);
    }

    return layerCounter;
}

const incrementLayerCounter = function(){
    layerCounter += 1;

    //change the scope
    hotkeys.setScope(`scope_${layerCounter}`);
}

const decrementLayerCounter = function(){
    if(layerCounter - 1 < 0) layerCounter = 0;
    else layerCounter -= 1;

    //change the scope
    hotkeys.setScope(`scope_${layerCounter}`);
}

const regsiterForm = function(formKey){
    if(!formKey) formKey =  (getTicks() + (++formIdCounter).toString()).toString();
    // console.log("new form key", formKey);

    return formKey;
}

const unRegsiterForm = function(formKey){
    if(formKey){
        //Take any action related to the form
        // console.log("after un register", formKey);
    }
}

const refreshDependentForms = function(callingAction, callingFormKey, depActions){
    if(depActions ||
        (callingAction.refreshMode && callingAction.refreshMode !== "none")){
        let depActionsList = depActions.split(",");

        //raise custom event which the forms will capture and trigger a render for themselves
        let event = new CustomEvent('runFormCommand', {
            detail: {
                commandName: "reload",
                callingAction: callingAction,
                depActionsList: depActionsList,
                depActions: depActions,
                callingFormKey: callingFormKey
            }
        });
        // dispatch the event
        document.dispatchEvent(event);
    }
}

const getOnSuccessCallBack = function(action, callBack){
    if(action){
        let customAction = action.actionSuccess;

        if (customAction) {
            if(customAction === "do-nothing"){
                callBack = undefined;
            }
            else{
                callBack = customAction;
            }
        }
    }

    return callBack;
}

const getCustomActionName = function (action, actionName) {
    if (action) {
        let actionNameFromCtrl = action.actionName;

        if (actionNameFromCtrl) actionName = actionNameFromCtrl;
    }

    return actionName;
}

const getCustomContainerKey = function (action) {
    return action ? action.containerKey : undefined;
}

//this is used for AC, where in formula will be passed and its interpreted value will be returned back
//this function will be used for acMethod (return value string), acDepCtrl (return value object)
//starting with # means it is like paramName and its value will come from another column of same row where paramName is matching
//starting with % means it is like the key and its value will come from the same column and within that column for matching key
const getFormDependantValue = (formula, formRow, currentColParamName) => {
    let params = {};
    //it is possible that formula can be a simple string or an object,
    //if it is a string then convet it to an object so that remaining operation can be completed
    let formulaObj = {};

    if (typeof formula == "object") {
        formulaObj = {...formula};
    }
    else{
        formulaObj = {"simple": formula};
    }

    if (formulaObj) {
        let paramKeys = Object.keys(formulaObj);

        paramKeys.forEach((key)=>{
            let pValue = formulaObj[key];

            //get the dynamic value
            if (typeof pValue == "string" && pValue.startsWith("#")) {
                let pValueObj = getFormRowVal(formRow, pValue.slice(1));

                if(pValueObj) pValue = pValueObj.val;
                else pValue = null;
            }
            //is path based, get value from the same column where the path matches
            else if (typeof pValue == "string" && pValue.startsWith("%")) {

                //get current column based on the param name that is passed
                let pValueObj = getFormRowVal(formRow, currentColParamName);

                if(pValueObj){
                    //get the key as per the path provided from the current column
                    pValue = getValueFromKey(pValueObj, pValue.slice(1), "");
                }
            }

            if(pValue){
                //set the value
                params[key] = pValue;
            }
        });
    }

    //if original formula was a string, then return a simple string instead of an object
    if (typeof formula == "object") {
        return params;
    }
    else{
        return params["simple"];
    }
};

const getFormRow = function(data, key) {
    if(data){
        // console.log("get row", key, data, data[key]);
        //if key value is first, then directly return the first row
        if(key && key !== "first"){
            //return the row matching the key
            return data[key];
        }
        else{
            //return first row (for a single row object)
            return data[Object.keys(data)[0]];
        }
    }

    return null;
}

const getFormData = (data, key, paramName) => {
    let row = getFormRow(data, key);

    return getFormRowVal(row, paramName);
}

const getFormRowVal = (row, paramName) => {
    if (row && paramName) {
        // console.log("row key value", rowKey, paramName, row.rowValues[paramName]);
        return row.rowValues[paramName];
    }

    return null;
}

//builds key value pair of the row provided
const getFormRowKeyValue = function(viewRow){
    let result = {};

    if(viewRow){
        let rowValsView = viewRow.rowValues;
        
        Object.keys(rowValsView).forEach((key)=>{
            let value = rowValsView[key].val;
            if(!value) value = "";
            result[key] = value;
        })
    }

    return result;
}

//this func will receive data and key
//key can be a string headerRows.menu.dataList.showSave
//func will go to that key and return its value
const getValueFromKey = function(data, key, defaultValue){
    if(data && key){
        // console.log("start value from key", key);
        //split the key with dots .
        let keys = key.split(".");
        let dataPart = data;
        let keyPart;

        for(let k = 0 ; k < keys.length ; k++){
            keyPart = keys[k];
            dataPart = dataPart[keyPart];
            if(!dataPart){
                break;
            }
        }

        // console.log("value from key", dataPart, key);

        return dataPart || defaultValue;
    }

    return defaultValue;
}

const updateFormValue = function(data, rowKey, valKey, value) {
    let result = {...data};

    //get the row to be edited
    let row = getFormRow(result, rowKey);

    // console.log("form value row", result, "row key", rowKey, "row", row, "val key", valKey, "value", value);
    if(row){
        //set new value
        if(value && typeof value == "object"){
            //for auto complete
            // console.log("on form value change", valKey);
            let rowVal = row.rowValues[valKey];
            if(rowVal){
                rowVal.val = value.key;
                rowVal.valF = value.text;
            }
        }
        else {
            //for a single value input
            let rowVal = row.rowValues[valKey];

            if(rowVal){
                row.rowValues[valKey].val = value;
            }
        }

        if(value && value.dataList){
            let rowVal = row.rowValues[valKey];

            if(rowVal){
                Object.keys(value.dataList).forEach((key)=>{
                    rowVal.dataList[key] = value.dataList[key]
                })
            }
        }
    }

    return result;
}

const replaceFormRows = function(viewRows, newRows) {
    if(newRows && viewRows){
        let result = {...viewRows};

        Object.keys(newRows).forEach((key)=>{
            result[key] = newRows[key];
        });

        // console.log("updated rows", result);

        return result;
    }
}

const replaceFormRowValues = (data, partialRows, matchRowType) => {
    
    if(data && partialRows){
        let result = {...data};
        
        Object.keys(partialRows).forEach((rowKey)=>{
            let partialRow = partialRows[rowKey];

            if(partialRow.rowType === matchRowType){
                let row = getFormRow(result, partialRow.rowKey);
                let newValues = partialRow.partialValues;

                if(row && newValues){
                    Object.keys(newValues).forEach((key)=>{
                        row.rowValues[key] = newValues[key];
                    });
                }
            }
        });
        // console.log("updated row values", result);

        return result;
    }

    //return original data as it is
    return data;
}

const deleteFormRow = function(viewRows, rowKeyToDelete) {
    if(rowKeyToDelete && viewRows){
        let result = {...viewRows};

        if (result.hasOwnProperty(rowKeyToDelete)) {
            delete result[rowKeyToDelete];
        }

        // console.log("updated rows after delete", result);

        return result;
    }
}

const generateRequestParams = function (action, viewRow) {
    var reqParam = {actionId: action.actionId};

    if (action.actionParam) reqParam = {...reqParam, ...action.actionParam};

    //TODO add support for dynamic grid
    //TODO add support for dashboard
    if (viewRow){
        reqParam.rowTypeId = viewRow.rtId;
        reqParam.rowId = viewRow.rId;
    }

    return reqParam;
}

export {regsiterForm, unRegsiterForm, refreshDependentForms,
    getValueFromKey, getFormRow, getFormData, getFormRowVal, updateFormValue, replaceFormRows, replaceFormRowValues, deleteFormRow, getFormRowKeyValue, getFormDependantValue,
    getOnSuccessCallBack, getCustomActionName, getCustomContainerKey, generateRequestParams,
    getCurrentLayer, incrementLayerCounter, decrementLayerCounter};

