import React from 'react';
import { useHistory } from 'react-router-dom';
import useSarasStore from '../hooks/localDB/useSarasStore';

const SarasNavigation = (props) => {
    const {menu, toN } = props;
    
    const localDb = useSarasStore();
    let history = useHistory();

    const onClick = (e) => {
        e.preventDefault();

        //add the menu to fav
        localDb.setTopMenu(menu);

        history.push(toN);
    }
    
    return (
        <a href={toN} style={{cursor:'pointer'}} onClick={onClick}>
            {props.children}
        </a>
    )
}

export default SarasNavigation
