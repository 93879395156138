import React from 'react';
import Box from '@material-ui/core/Box';

const SarasBox = (props)=>{
    return(
        <Box mt={props.mt}>
              {props.children}
            </Box>
    )
}

export default SarasBox