import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import ListItemText from '@material-ui/core/ListItemText';
import SarasPopup from '../../container/popup/SarasPopup';
import SarasTrans from '../../notifications/SarasTrans';

const SarasMenu = (props) => {
  const { menuContainerClass, menuTrigger, dataList, onShouldHideOption, menuItem: ChildMenuItem, onSelection, hideTriggerIfNoOptions } = props;

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  // console.log('top menu datalist', dataList);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const shouldHide = (row) => {
    if(onShouldHideOption) return onShouldHideOption(null, row);

    return false;
  }

  const handleSelection = (event) => {
      let index = event.currentTarget.getAttribute('rowKey');
      // console.log("clicked", event, event.currentTarget.getAttribute('rowKey'));
      if(onSelection) onSelection(event, index);
      setOpen(false);
  };

  const onChildAction = (event, index) => {
    if(onSelection) onSelection(event, index);
    setOpen(false);
  }

  const handleClose = (event) => {
    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const renderMenuTrigger = () => {
    // console.log("render trigger", menuTrigger);
    if(menuTrigger != null) {
      return menuTrigger;
    }
    else {
      return <span><SarasTrans value = "Menu"/></span>
    }    
  }

  if((!dataList || dataList.length === 0) && hideTriggerIfNoOptions === 1)
  {
    return null;
  }

  return (
      <>
        <span className={menuContainerClass || ''}>
          <span
            ref={anchorRef}
            aria-controls={open ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
          >
            {renderMenuTrigger()}
          </span>
        </span>
        <SarasPopup anchorRef={anchorRef} open={open} onClose={handleClose}>
            <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
              {dataList.map((row, index)=>(
                  shouldHide(row) ? null : <MenuItem key={index} onClick={handleSelection} rowkey={index}>
                    <ListItemText>
                      <ChildMenuItem viewData={row} index={index} onChildAction={onChildAction}>
                      </ChildMenuItem>
                    </ListItemText>
                  </MenuItem>
              ))}
            </MenuList>
        </SarasPopup>
      </>
  );
}

export default SarasMenu;