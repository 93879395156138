import React, {useState, useEffect, useRef} from 'react';
import SarasPopup from '../../../library/container/popup/SarasPopup'
import SarasTrans from '../../../library/notifications/SarasTrans'
import useSarasStore from '../../../library/hooks/localDB/useSarasStore'
import coreSettings from '../../../helper/coreSetting';
import useFormApi from '../../../view/cmn/docAutomation/useFormApi'
import {LanguageIcon} from '../../../library/decor/SarasIcons';
import {reloadWindow} from '../../../helper/uiFunctions';
import SarasDialog from '../../../library/container/popup/SarasDialog3';

const LanguageSelectorPopup = (props) => {
    const { currentCulture, targetRef, onChange, onCancel } = props
    const [cultureList, setCultureList] = useState(null);
    const formApi = useFormApi();

    const getCultureList = () => {
        if(!cultureList)
        {
            //request for the cultures list from the server
            let localAction = { actionId: "get", actionName: "userData", actionParam: {'content-type':'locale-list'}, containerKey:""};
            formApi.runGenericAction(localAction, "userData", null, null, null, "", "", 0, onListSuccess);
        }
    };

    useEffect(getCultureList, [currentCulture]);

    const onListSuccess = (action, containerKey, rowKey, headerKey, response) => {
        // console.log("culture locale list response", response);
    
        if(response){
            setCultureList(response);
        }
    }

    const handleClose = (event) => {
        // setOpen(false);
        onCancel();
    };

    const cancelFun = () => {
        onCancel();
    }

    const onCultureChange = (c) => {
        onChange(c);
    }

    return (
        <SarasPopup anchorRef={targetRef} open={true} onClose={handleClose}>
            <SarasDialog formStyle={{ width: '300px', height:'200px' }} 
                contentStyle={{ height:'95px', overflow:'hidden' }}  title={<SarasTrans value='Change Language (Beta)' />} 
                cancelName='Cancel' cancelFunction={cancelFun} >
                <div className="uiCultureList">
                    {cultureList && cultureList.map((c, index) => (
                        <span className={c.toUpperCase() === currentCulture.toUpperCase() ? 'uiActive' : 'uiLink'} onClick={() => {onCultureChange(c.toUpperCase())}} >{c.toUpperCase()}</span>
                    ))}
                    <div className='uiSpacer5'><SarasTrans trKey='TranslationDescription' /></div>
                </div>
            </SarasDialog>
        </SarasPopup>
    )
}

const UserCulture=(props)=>{
    const [popupName, setPopupName] = useState('');
    const [myCulture, setMyCulture] = useState('');
    const cultureRef = useRef();
    const localDb = useSarasStore();
    const formApi = useFormApi();

    const checkCulture = () => {
        if(!myCulture)
        {
            //get selected culture
            localDb.requestData("my_culture", onMyCultureRetrieved);
        }
        else
        {
            localDb.setData("my_culture", myCulture);
            //check if culture locale is available or not
            // console.log("check culture locale");
            //check if the culture is available in locale, if not then request from server
            localDb.requestData("downloaded_culture", onCurrentCultureRetrieved);
        }
    };

    useEffect(checkCulture, [myCulture]);

    //this is executed when my culture is retrieved from local db
    const onMyCultureRetrieved = (key, value) =>  {
        let newValue = value;

        //default to EN culture
        if(!newValue) newValue = coreSettings.appDefaultCulture;

        if((myCulture || '').toLowerCase() !== (newValue || '').toLowerCase()){
            setMyCulture(newValue);
        }
    }

    //this is executed when current culture is retrieved from local db
    const onCurrentCultureRetrieved = (key, value) =>  {
        //if the culture in the locale is matching the selected culture, it means the translation is already available 
        //hence do not take any action

        // console.log("culture onCurrentCultureRetrieved", myCulture, value);

        if((myCulture || '').toLowerCase() !== (value || '').toLowerCase()){
            //request for the culture translation strings from the server
            // console.log("menu request triggered");
            let localAction = { actionId: "get", actionName: "userData", actionParam: {'content-type':'app-locale', 'culture': myCulture.toLowerCase()}, containerKey:""};
            formApi.runGenericAction(localAction, "userData", null, null, null, "", "", 0, onLocaleSuccess);
        }
    }

    const onLocaleSuccess = (action, containerKey, rowKey, headerKey, response) => {
        // console.log("culture locale response", response);
    
        if(response && response.dataList 
            && response.dataList.locale){

                const locale = response.dataList.locale;
        
                const jLocale = JSON.parse(locale);
                
                // console.log("culture locale json", jLocale);
                localDb.setData("downloaded_culture", jLocale.language);
                localDb.setStrings(jLocale.strings, 1, onCultureDataUpdated);
            }
      }

      const onCultureDataUpdated = () => {
          reloadWindow(false);
      }

      const changeCultureClicked = () => {
        setPopupName("show");
    }

    const onConfirmChange = (newCulture) => {
        //check local db, if the culture is not available then request from server
        
        //store this culture in general
        if((myCulture || '').toLowerCase() !== (newCulture || "").toLowerCase()){
            // console.log("new culture", newCulture);
            setMyCulture(newCulture.toLowerCase());
        }

        setPopupName("");
    }

    const onCancelChange = () => {
        setPopupName("");
    }

    const renderPopup = () => {
        if (popupName === 'show') {
            return (
            <LanguageSelectorPopup targetRef={cultureRef} 
                currentCulture={myCulture}
                onChange={onConfirmChange} 
                onCancel={onCancelChange} >
            </LanguageSelectorPopup>);
        }

        return null;
    }

    return(
        <>
            <span ref={cultureRef} className="uiAppIconWithLink" onClick={changeCultureClicked}>
                <LanguageIcon className='uiAppIcon'/>
                <span>{(myCulture || '').toUpperCase()}</span>
            </span>
            {renderPopup()}
        </>
    )
}

export default UserCulture