import React from 'react'
import SarasTrans from '../../../library/notifications/SarasTrans';
import {getPublicMenuImg} from '../../../helper/uiFunctions';

const UserFavouriteMenuRow = (props) => {
    const { viewData } = props;

    return (
        <span className="uiTopMenuRow">
            <img alt="icon" src={getPublicMenuImg(viewData.MenuIcon)} />
            <span className="uiMenuItemLabel"><SarasTrans value = {`${viewData.MenuTitle}`}/></span>
        </span>
    )
}

export default UserFavouriteMenuRow