import React from 'react';

const SarasLink = (props)=>{
    return(
        <a style={{color:props.color}} target={props.target} href={props.href}>
          {props.text}
        </a>
    )
}

export default SarasLink