import React from 'react';
import { useSelector } from "react-redux";
import SarasMsg from './SarasMsg';

//this component is used for displaying notifications from the redux state
const DisplayMessages = () => {
  const notifications = useSelector(state => state.notifications);

  return (
      <SarasMsg notification={notifications} />
  );
}

export default DisplayMessages;
