import {putGeneralData, getGeneralData, clearLocale, putBulkLocale, getLocaleString} from '../../../helper/localStorage'
import {validateVersion} from "../../../helper/uiFunctions";

const useSarasStore = () => {

    const setTopMenuChanged = () => {
        const promise = getGeneralData("top_menu_change_count");

        promise.then((result) => {
            let changeCount = 1;

            if(result) {
                changeCount = result + 1;
            }

            setData("top_menu_change_count", changeCount);
  
            // console.log("top menu", searchTopMenu, result);
        }).catch(e => {
            // Other error such as a string was thrown
            // console.error (e);
        });
    }

    const getTopMenuChangeCount = (onSuccess) => {
        requestData("top_menu_change_count", onSuccess);
    }

    const setTopMenu = (menuClicked) => {
        //add the menu to fav
        const promise = getGeneralData("app_menu");

        promise.then((result) => {
            let searchTopMenu = result.filter(
                function(value){ return value.MenuType === "f" }
            );
  
            if(searchTopMenu && searchTopMenu.length > 0){
                let existingTopMenu = searchTopMenu[0].ChildMenus.filter(
                    function(value){ return value.MenuLink === menuClicked.MenuLink }
                );

                if(existingTopMenu && existingTopMenu.length > 0){
                    //only increase the use count
                    existingTopMenu[0].UseCount = existingTopMenu[0].UseCount + 1;
                }
                else{
                    let menuUsed = {...menuClicked};
                    //add new menu and set use count to 1
                    menuUsed.UseCount = 1;
                    searchTopMenu[0].ChildMenus.push(menuUsed);
                }

                setAppMenu(result);
                setTopMenuChanged();
            }
  
            // console.log("top menu", searchTopMenu, result);
        }).catch(e => {
            // Other error such as a string was thrown
            // console.error (e);
        });
    }

    //this will be used for comparing if the version of the app has changed
    //whenever version changes system will auto signout the users and they will need to reauthenticate
    //comparison will be done between the value received from server in the server attributes with the value in local store
    //auto signout will be performed only when major or minor version changes, not when patch version change
    //format 3.1.1 <- major.minor.patch
    const setAppVersion = (version, callback) => {
        //validate the version
        if(validateVersion(version)){
            setData("app_version", version, callback);
        }
        else{
            //return failure
            callback(0);
        }
    }

    const getAppVersion = (onSuccess) => {
        requestData("app_version", onSuccess);
    }

    const setAppMenu = (menuData) => {
        setData("app_menu", menuData);
    }

    const getAppMenu = (onSuccess) => {
        requestData("app_menu", onSuccess);
    }

    const setData = (key, value, callback) => {
        // console.log("local data set", key, value);

        const promise = putGeneralData(key, value);

        promise.then((result) => {
            // console.log("local data inside promise", result);
            if(callback) callback(result);
        }).catch(e => {
            // Other error such as a string was thrown
            // console.error (e);
        });
    }

    const requestData = (key, callback) => {
        //return empty if key is not a string or is empty
        if(!key || typeof key != "string") {
            if(callback) callback(key, "");
        }
        else{
            const promise = getGeneralData(key.trim());

            promise.then((result) => {
                // console.log("local data inside promise", result);
                if(callback) callback(key, result);
            }).catch(e => {
                // Other error such as a string was thrown
                // console.error (e);
            });
        }
    }

    const setStrings = (data, emptyAll, callback) => {
        if(emptyAll === 1){
            const promise = clearLocale();

            promise.then(() => {
                requestBulkAddLocal(data, callback);
            }).catch(e => {
                // Other error such as a string was thrown
                // console.error (e);
            });
        }
        else{
            requestBulkAddLocal(data, callback);
        }
    }

    const requestBulkAddLocal = (data, callback) => {
        const promise = putBulkLocale(data);

        promise.then((result) => {
            if(callback) callback(result);
        }).catch(e => {
            // Other error such as a string was thrown
            // console.error (e);
        });
    }

    const requestString = (key, callback)=>{
        //return empty if key is not a string or is empty
        if(!key || typeof key != "string") {
            if(callback) callback(key, "");
        }
        else{
            const promise = getLocaleString(key.trim());

            promise.then((result) => {
                // console.log("local data inside promise", result);
                if(callback) callback(key, result);
            }).catch(e => {
                // Other error such as a string was thrown
                // console.error (e);
            });
        }
    }

    const returnObj = {
        setData, 
        requestData, 
        setStrings, 
        requestString,
        setAppVersion,
        getAppVersion,
        setAppMenu,
        getAppMenu,
        setTopMenu,
        getTopMenuChangeCount
    }

    return returnObj;
}

export default useSarasStore;
