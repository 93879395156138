import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import SarasMenu from '../../../library/repeater/menu/SarasMenu';
import SarasMenuItem from '../../../library/repeater/menu/SarasMenuItem';
import {IconPerson} from '../../../library/decor/SarasIcons';
import * as uiJS from '../../../helper/uiFunctions';

const userMenu = [
    {"user": "View / Update profile"},
    {"signout": "Signout"}
]

const urls = {
    "user" : {"dtId":"7", "module":"system", "view":"user"},
    "signout":{"module":"signout"}
}

const UserNavigation = ()=> {
    var user = useSelector(state => state.auth.user);
    let history = useHistory();
    var userFName;
    var userProfMenuName;

    if(user != null){
        userFName = user.FullName.split(' ')[0];
        userProfMenuName = userMenu[0].user;

        if(userProfMenuName.indexOf(userFName) === -1)
        {
            userMenu[0].user = `${userProfMenuName} - ${userFName}` ;
        }
    }

    const renderMenuTrigger = () => {
        return (
            <span className='uiAppIconWithLink'>
                <IconPerson className='uiAppIcon'/>
                <span>{userFName}</span>
            </span>
        )
    }

    const onItemSelection = (e, index) => {
        let urlKey = Object.keys(userMenu[index])[0];
        let urlObj = urls[urlKey];
        let docId;
        let url;
        if(urlKey === "user"){
            docId = user.UserId;
            url = uiJS.getShowDocumentLink(urlObj.dtId,docId,"",0,urlObj.module,urlObj.view);
        }
        else if(urlKey === "signout"){
            url =  `/${urlObj.module}`;
        }
        
        history.replace(url);
    }
  
    return(
        <SarasMenu menuTrigger={renderMenuTrigger()} dataList={userMenu} menuItem={SarasMenuItem} onSelection={onItemSelection}></SarasMenu>
        );
}
export default UserNavigation;