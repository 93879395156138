import React from 'react';
import GridMenu from './GridMenu';

const Home = () => {
    return (
        <>
            <GridMenu></GridMenu>
        </>
    )
}

export default Home;