import React, { useEffect, useState } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import clsx from 'clsx';
import { amber, green } from '@material-ui/core/colors';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';
import SarasMsgRow from './SarasMsgRow';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon,
  };

const useStyles1 = makeStyles(theme => ({
    success: {
        backgroundColor: green[600],
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: amber[700],
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: 'flex',
        alignItems: 'center',
    },
}));

//messages according to the language selected
const SarasMsg = (props) => {
    const { notification } = props;
    const classes = useStyles1();
    const [open, setOpen] = useState(false);
    const [msgType, setMsgType] = useState("success");
    
    // console.log("sso m", notification);

    const getMessageType = (notify) => {
        if(notify){
            if (notify.msgType === 0) {
                return "success";
            }
            else if (notify.msgType === 1) {
                return "error";
            }
            else if (notify.msgType === 2) {
                return "info";
            }
        }

        return "success";
    }

    useEffect(() => {
        if (notification) {
            if ((notification.messageList && Object.values(notification.messageList).length > 0)
               || notification.msg) {
                setMsgType(getMessageType(notification));
                setOpen(true);
            }
        }
    }, [notification]);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };
    
    if(!notification)
        return(null)

    const Icon = variantIcon[msgType];

    return (
        <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open}
            autoHideDuration={10000} onClose={handleClose} >
            <SnackbarContent
            className={classes[msgType]}
            aria-describedby="client-snackbar"
            message={
                <span id="client-snackbar" className={classes.message}>
                <Icon className={clsx(classes.icon, classes.iconVariant)} />
                <SarasMsgRow notification={notification.msg} />
                {
                    notification.messageList && Object.values(notification.messageList).map((msg, index)=>(
                        <SarasMsgRow notification={msg} key={index}/>
                    ))
                }
                </span>
            }
            action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                <CloseIcon className={classes.icon} />
                </IconButton>
            ]}
            />
        </Snackbar>
    );
}

export default SarasMsg;
