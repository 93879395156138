import React from 'react';
import List from "@material-ui/core/List";

const SarasList = (props)=>{
    // console.log('qqq',props);
    return(
        <List component={props.component} style={props.style} className={props.className}>
            {props.children}
        </List>
    )
}

export default SarasList;