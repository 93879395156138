import coreSettings from './coreSetting';
import moment from 'moment';
import { generatePath } from 'react-router';
import * as browsers from './browserTypes';

const getBrowserId = function () {
    const isChrome = navigator.userAgent.includes("Chrome") && navigator.vendor.includes("Google Inc");

    if(isChrome){
        return browsers.CHROME_BROWSER;
    }

    // if (window.chrome && !window.opr){
    //     isChrome = true;
    // }

    return browsers.UNKNOWN_BROWSER;
}

//search and return the first matching device from the connected list that is also in the devices supported
const findDeviceRequired = function(devicesSupported, physicalDevicesConnected){
    if(devicesSupported && physicalDevicesConnected) {
        for(let p = 0 ; p < physicalDevicesConnected.length ; p++){

            for(let i = 0 ; i < devicesSupported.brands.length ; i++){
                if(devicesSupported.brands[i].vendorId === physicalDevicesConnected[p].vendorId && 
                    devicesSupported.brands[i].productId === physicalDevicesConnected[p].productId){
                    return physicalDevicesConnected[p];
                }
            }
        }
    }

    return null;
}

const findDeviceLanguage = function(devices, vendorId, productId, defaultLang){
    if(devices) {
        for(let i = 0 ; i < devices.lang.length ; i++){
            if(devices.lang[i].vendorId === vendorId && devices.lang[i].productId === productId){
                return devices.lang[i].language;
            }
        }
    }

    return defaultLang || "esc";
}

const findDeviceSupportedBrands = function(devices, key, printType){
    // console.log("report browser extension", extensions, key, extensions[key]);
    const device = devices[key];
    if(device) {
        let browserId = getBrowserId();

        if(browserId !== browsers.UNKNOWN_BROWSER){
            let deviceOfType = device.deviceTypes[printType];

            if(deviceOfType){
                return {"browser": browserId, "brands": deviceOfType.vendorsSupported, "lang": deviceOfType.vendorsLanguage};
            }
        }
    }

    return null;
}

const getReferrerUrl = function (signInRoute, path){
    if(path && signInRoute){
        let pathParts = path.split(signInRoute);

        //if url is valid then only 2 parts will be generated
        if(pathParts.length === 2){
            let referrer = pathParts[1];

            //return the url decoded version of the referrer
            if(referrer && referrer.length > 5 && referrer.indexOf("/http") === 0) {
                referrer = referrer.substring(1);
                
                let decodedReferrer = decodeURIComponent(referrer);

                // console.log("login 3", referrer, decodedReferrer);

                return decodedReferrer;
            }
        }
    }

    return "";
}

const reloadWindow = function (serverRefresh){
    if(!serverRefresh) serverRefresh = true;

    //trigger window reload
    window.location.reload(serverRefresh);
}

const replacePathPart = function (match, partKey, partValue, part2Key, part2Value) {
    // console.log("replace module", partKey, partValue);
    match.params[partKey] = partValue;

    if (part2Key && part2Value) {
        match.params[part2Key] = part2Value;
    }

    const targetUrl = generatePath(match.path, match.params);

    // console.log("new path", targetUrl);

    return targetUrl;
}

const getCookie = function (cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let i = 0; i <ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
};

const getTimezoneOffset = function () {
    var offset = new Date().getTimezoneOffset();
    return offset;
};

/* url building functions */
const getBaseURL = function () {
    //get the server base url - factor in application root and https protocol
    let appLocation = document.location;

    let resUrl = appLocation.protocol + "//" + appLocation.host + appLocation.pathname;

    return resUrl;
}

const getBaseAPIURL = function () {
    let apiUrl = getBaseURL() + "apiv3/";

    return apiUrl;
}

const getShowDocumentLink = function (doctyid, docid, param, attachBaseUrl, module, view) {
    let moduleName = module || 'doc';
    let viewName = view || 'doc';
    var url;

    if (doctyid && docid) {
        url = `/${moduleName}/${viewName}/show/${doctyid}/${docid}`;
        if (param) url = url + "/" + param;
    }
    else if (doctyid && !docid) {
        url = `/${moduleName}/${viewName}/main/${doctyid}`;
    }

    if (url && attachBaseUrl === 1) {
        var docUrl = getBaseURL() + "#" + url;

        return docUrl;
    }
    else {
        return url;
    }
}

const getSearchDocumentLink = function (doctyid, module, view) {
    if (doctyid) {
        let moduleName = module || 'doc';
        let viewName = view || 'doc';

        var url = `/${moduleName}/${viewName}/search/${doctyid}`;

        //base url not used
        // var docUrl = getBaseURL() + "#" + url;

        return url;
    }

    return undefined;
}

//possible values of file types are: (pass the file types from the function that is calling getFileDownloadLink)
//"t" use this to download generated reports
//"rt" use this to download report template
//"it" use this to download import template
const getFileDownloadLink = function (fileName, fileType, isOpenUrl) {
    //generate file url according to this pattern
    let url = `${getBaseAPIURL()}rs?an=getFile&fn=${fileName}&ft=${fileType}`;
    if (isOpenUrl)
        window.open(url);
    else
        return url;
}

const getFormFieldValue = function (dataList, field) {
    if (dataList && dataList.hasOwnProperty(field)) {
        return dataList[field];
    }

    return undefined;
}

const isEven = function (testValue) {
    return parseFloat(testValue) % 2 === 0;
}

const isNumeric = function (testValue) {
    return !isNaN(parseFloat(testValue)) && isFinite(testValue);
}

const parseDate = function (testValue) {
    //list formats to be accepted here
    var inputFormats = coreSettings.dateInputFormats;
    var dateValue = moment(testValue, inputFormats, true);

    return dateValue;
}

const isValidDate = function (testValue) {
    var dateValue = parseDate(testValue);

    return (dateValue.isValid());
}

const getTicks = function () {
    // let valof = moment().valueOf();            // xxxxxxxxxxxxx
    // let getTime = moment().toDate().getTime(); // xxxxxxxxxxxxx
    // let unixTime =  moment().unix();           // xxxxxxxxxx
    // let formatTimex =  moment().format('x');   // with milliseconds
    // let unixFormatX = moment().format('X');    // without milliseconds

    // console.log("Checking time capabilities of moment");
    // console.log(valof);
    // console.log(getTime);
    // console.log(unixTime);
    // console.log(formatTimex);
    // console.log(unixFormatX);

    return moment().format('x');
}

//use this to convert a string to key value object
const convertStrToKeyValue = (formParam, level1SplitCh, level2SplitCh) => {
    var jsonParam = {}

    if(formParam){
        if (!level1SplitCh) level1SplitCh = "&";
        if (!level2SplitCh) level2SplitCh = "=";

        formParam.split(level1SplitCh).forEach((param) => {
            let paramsplit = param.split(level2SplitCh);
            if (paramsplit.length === 2)
                jsonParam[paramsplit[0]] = paramsplit[1]
        })
    };

    return jsonParam;
}

const convertMinutesToHHMM = (minutes) => {
    let iMin = 0;

    if (minutes) {
        iMin = parseInt(minutes, 10);
    }

    let mnsPart = iMin % 60;
    let hrsPart = (iMin - mnsPart) / 60;

    let mnsAsString = mnsPart.toString();
    let hrsAsString = hrsPart.toString();

    if (mnsPart < 10) mnsAsString = "0" + mnsAsString;
    if (hrsPart < 10) hrsAsString = "0" + hrsAsString;

    return hrsAsString + ":" + mnsAsString;
}

const convertHHMMFormatToMinuets = (hhmm) => {

    if (hhmm) {

        let fHhmm = hhmm;

        //colon is missing, add a colon
        if (fHhmm.indexOf(':') === -1) {
            if (fHhmm.length === 4) {
                fHhmm = hhmm.substring(0, 2) + ":" + hhmm.substring(2, 4);
            }
            else{
                return parseInt(fHhmm, 10);
            }
        }

        //check if the current time has crossed the allocated alarm time
        var timeComponenets = fHhmm.split(':');

        if (timeComponenets.length === 2) {
            let hours = parseInt(timeComponenets[0], 10);
            let mins = parseInt(timeComponenets[1], 10);

            let totalMins = (hours * 60) + mins;

            return totalMins;
        }
    }

    return 0;
}

const formatUnicorn = (input, params) => {
    var str = input.toString();

    if (params && params.length) {
        var key;

        for (key in params) {
            str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), params[key]);
        }
    }

    return str;
};

const multiLingualEncoding = (strToEncode) => { 
    let encodedStr;
    encodedStr = strToEncode.replaceAll(" ","%20");
    return encodedStr;
}

const multiLingualDecoding = (strToEncode) => { 
    let encodedStr;
    encodedStr = strToEncode.replaceAll("%20"," ");
    return encodedStr;
}

const getPublicResImg = function (resourceCat, fileName){
    let resLink;

    if(resourceCat && fileName){
        resLink = formatUnicorn("{0}/res/theme/default/images/{1}/{2}", [process.env.PUBLIC_URL, resourceCat, fileName]);
    }

    // console.log("image", resLink, process.env.PUBLIC_URL);
    
    return resLink;
}

const getPublicUxImg = function (fileName){
    let resLink = getPublicResImg("ux", fileName);

    return resLink;
}

const getPublicMenuImg = function (fileName){
    let resLink = getPublicResImg("menu", fileName);

    if(!resLink){
        resLink = getPublicResImg("menu", "DefaultMenuIcon.png");
    }

    return resLink;
}

const smartSearch = function (source, sParam){
    if(!sParam || !source) return false;

    //if there is only 2 characters return the string that starts with the character
    if(sParam.length <= 2) {
        return (source.toLowerCase().split(" ").find((str) => str.indexOf(sParam) === 0));
    }

    return (source.toLowerCase().indexOf(sParam) >= 0);
}

const compareNumber = function (val1, val2){
    //it is possible val1 is not a number and also possible that the number is an int, long or double
    //also possible that val1 is empty
    
    // eslint-disable-next-line
    return val1 == val2;
}

const validateVersion = function(version){
    if(!version) return 0;

    let vParts = version.split(".");

    if(vParts.length !== 3) return 0;

    //return 0 if the value before and after parsing is not matching, 
    //it means the version is non numeric and is compromised
    //this will not handle versions that has legitimate text value in them
    if(!compareNumber(vParts[0], parseInt(vParts[0]))) return 0;
    if(!compareNumber(vParts[1], parseInt(vParts[1]))) return 0;
    if(!compareNumber(vParts[2], parseInt(vParts[2]))) return 0;

    return 1;
}

//if trigger level is 1 then changes in major will return 1
//if trigger level is 2 then changes in major or minor will return 1
//if trigger level is 3 then changes in major, minor or patch version will return 1
const versionChanged = function (storedVersion, appVersion, triggerLevel){
    //means versioning has not started
    if(!storedVersion && !appVersion) return 0;
    
    //version control started and there is no stored value to compare it with
    if(!storedVersion && appVersion) return 1;
    
    //split the versions and begin comparing
    let svParts = storedVersion.split(".");
    let avParts = appVersion.split(".");

    if(svParts.length !== avParts.length || svParts.length !== 3)
    {
        //invalid versioning
        return -1;
    }

    let majorChanged = parseInt(svParts[0]) !== parseInt(avParts[0]);
    let minorChanged = parseInt(svParts[1]) !== parseInt(avParts[1]);
    let patchChanged = parseInt(svParts[2]) !== parseInt(avParts[2]);

    if(triggerLevel === 1){
        return majorChanged ? 1 : 0;
    }
    else if(triggerLevel === 2){
        return (majorChanged || minorChanged) ? 1 : 0;
    }
    else if(triggerLevel === 3){
        return (majorChanged || minorChanged || patchChanged) ? 1 : 0;
    }

    return 0;
}

export {
    replacePathPart, getBaseURL, getBaseAPIURL, getShowDocumentLink, getSearchDocumentLink,
    getFormFieldValue, convertStrToKeyValue, getFileDownloadLink,
    convertMinutesToHHMM, convertHHMMFormatToMinuets, formatUnicorn,
    isNumeric, isValidDate, parseDate, getTicks, getTimezoneOffset,
    getCookie, reloadWindow, multiLingualEncoding, multiLingualDecoding,
    getPublicResImg, getPublicUxImg, getPublicMenuImg,
    getReferrerUrl, smartSearch, getBrowserId, findDeviceSupportedBrands, findDeviceLanguage, findDeviceRequired,
    compareNumber, isEven, versionChanged, validateVersion
};
