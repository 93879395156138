import React, {useEffect, useState} from 'react';
import { NavLink } from "react-router-dom";
import useSarasStore from '../../../library/hooks/localDB/useSarasStore'
import { useHistory } from 'react-router-dom';
import {getPublicMenuImg} from '../../../helper/uiFunctions';
import SarasTrans from '../../../library/notifications/SarasTrans'
import {HomeIcon} from '../../../library/decor/SarasIcons';
import SarasHotKeys from '../../../library/notifications/SarasHotKeys';
import UserFavouriteMenu from './UserFavouriteMenu';

const ShortcutMenuRow = ({menuRow}) => {

  const renderMenuRow = (childRow, index) => {
    if (!childRow.MenuTitle)
      return (null)
  
    return (
        <NavLink to={childRow.MenuLink} key={index} className="uiShortcutMenuRow">
          <img alt="icon" src={getPublicMenuImg(childRow.MenuIcon)} />
          <span><SarasTrans value={childRow.MenuTitle} /></span>
        </NavLink>
    )
  }

  if(menuRow.ChildMenus.length > 0)
    return (
      <>
        {menuRow.ChildMenus.map((childRow, index) => (
            renderMenuRow(childRow, index)
        ))}
      </>
    );

  return null;
}

const ShortcutMenu = (props) => {
    const [appMenus, setAppMenus] = useState([]);
    const localDb = useSarasStore();
  
    let history = useHistory();

    const loadLocalMenu = () => {
      if(!appMenus || appMenus.length === 0)
      {
        localDb.getAppMenu(onLocalMenuSuccess);
      }
    };
  
    useEffect(loadLocalMenu, [appMenus]);
  
    const onLocalMenuSuccess = (key, value) =>  {
      if(value && typeof value != "string"){
        setAppMenus(value);
      }
  }

    const onHomePress = () => {
      history.replace("/home");
    }

  return (
    <>
        <div className="uiShortcutMenu">
            <SarasHotKeys ksc='home' onHotKey={onHomePress}>
              <NavLink to="/home">
                <span className='uiAppIconWithLink'>
                  <HomeIcon className='uiAppIcon'/>
                  <span><SarasTrans value="Home" /></span>
                </span>
              </NavLink>
            </SarasHotKeys>
            <UserFavouriteMenu viewData={appMenus}/>
            {appMenus && appMenus.map((menu, index) => (
                menu.MenuType === "s" ? <ShortcutMenuRow menuRow={menu} key={index}/>
                : null
            ))}
        </div>
    </>
    )
}

export default ShortcutMenu;
