import React, {Suspense} from 'react';
import {HashRouter as Router} from 'react-router-dom';
import SARASRoutes from './layout/main/SARASRoutes';
import ErrorBoundary from './library/notifications/ErrorBoundary';
import SarasTrans from './library/notifications/SarasTrans';

const App = () => {

  return (
    <div>
      <Router >
        <ErrorBoundary>
          <Suspense fallback={<div><SarasTrans value = "Loading..."/></div>}>
            <SARASRoutes />
          </Suspense>
        </ErrorBoundary>
      </Router>
    </div>
  );
}

export default App;
