import React from 'react';
import SarasTrans from '../../../library/notifications/SarasTrans';

const Download = () => {
    return (
        <div>
            <div><SarasTrans value = "Your requested content is downloading."/></div>
        </div>
    )
}

export default Download;