import React, {useState} from 'react';
import SarasList from '../../../library/repeater/list/SarasList';
import SarasListItem from '../../../library/repeater/list/SarasListItem';
import SarasDivider from '../../../library/decor/divider/SarasDivider'
import SarasCollapse from '../../../library/container/collapse/SarasCollapse';
import SarasTrans from '../../../library/notifications/SarasTrans'
import SarasNavigation from '../../../library/navigate/SarasNavigation';
import Icon,{ExpandLessIcon,ExpandMoreIcon} from '../../../library/decor/SarasIcons';
import {getPublicMenuImg} from '../../../helper/uiFunctions';
import './AppMenu.scss';

const AppMenuRow = ({menuRow}) => {
  const isExpandable = menuRow.ChildMenus && menuRow.ChildMenus.length > 0
  const [open, setOpen] = useState(false);
  
  function handleParentClick() {
    document.querySelectorAll('.subMenu.MuiCollapse-entered').forEach(menu=>menu.previousElementSibling.firstElementChild.click())
    setOpen(!open)
  }

  // const handelMenuClose = () => {
  //   setOpen(false)
  // }

  const renderParentMenu = () => {
    // console.log("in parent", menuRow);
    var MIcon = null;

    if(menuRow.MenuIcon.length>0 )
      MIcon = Icon[menuRow.MenuIcon];
      
    if(menuRow.MenuLink === '' && menuRow.MenuTitle === '')
    {
      return (<SarasDivider variant="middle" />);
    }
    else
    {
      return (
        <SarasListItem onclick={handleParentClick} ListIcon={MIcon} iconClass='uiAppIcon' 
        text={<SarasTrans value={menuRow.MenuTitle} />}
        executeBeforeEnd={isExpandable && !open ?<ExpandMoreIcon />:<ExpandLessIcon />}>
        </SarasListItem>
      );
    }
  }

  const renderChildMenu = () => {
    if(isExpandable)
    {
      return (
        <SarasCollapse in={open} className='subMenu'>
          <SarasDivider />
          <SarasList>
            {menuRow.ChildMenus.map((childRow, index) => (
              renderChildMenuRow (childRow, index)
            ))}
          </SarasList>
        </SarasCollapse>
      );
    }
    else
    {
      return <></>;
    }
  }

  const renderChildMenuRow = (childRow, index) => {
    if(!childRow.MenuTitle)
      return(null)

    return (
      <SarasNavigation menu={childRow} toN={childRow.MenuLink} >
        <SarasListItem onclick={handleParentClick}
          text={
          <span className='uiAppMenuRow'>
            <img alt="icon" src={getPublicMenuImg(childRow.MenuIcon)} /><SarasTrans value={childRow.MenuTitle} />
          </span>}
          ></SarasListItem>
      </SarasNavigation>
    )
  }

  return (
      <>
        {renderParentMenu()}
        {renderChildMenu()}
      </>
  )
}

export default AppMenuRow;
