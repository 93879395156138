
import React from 'react';
import Typography from '@material-ui/core/Typography';

const SarasTypography = (props)=>{
    return(
        <Typography id={props.id} component={props.component} variant={props.variant} 
        color={props.color} 
        align={props.align} 
        style={props.style}
        gutterBottom={props.gutterBottom}
        className={props.className}
        >
              {props.children}
            </Typography>
    )
}

export default SarasTypography