import { useEffect, useState } from 'react';
import useSarasStore from '../hooks/localDB/useSarasStore';
import {formatUnicorn} from '../../helper/uiFunctions';

const SarasMsgRow = (props) => {
    const { notification } = props;
    
    //status 0 means not read for translation, 1 means ready for translation, 2 means translated
    const [localeValue, setLocaleValue] = useState({key:'', value:'', params:null, status: 0});

    const localDb = useSarasStore();
    
    const initialiseNotification = () => {

        // console.log("notification row", localeValue);

        //notification can be a string or an object : 
        //notification from server will always be an object, 
        //whereas that received directly from react may be a string
        //prepare for translation if status is 0
        if(localeValue.status === 0){
            if(notification){
                if((typeof notification == "string") && notification.length  > 0){
                    setLocaleValue({key: notification, value: notification, params: null, status: 1});
                }
                else if((typeof notification == "object") 
                    && notification.FormattedMessage
                    && notification.FormattedMessage.length  > 0){
                        setLocaleValue({
                            key: notification.Code, value: notification.FormattedMessage, 
                            params: notification.FormatterParams, status: 1});
                }
            }
        }
        //translate if status is 1
        else if(localeValue.status === 1){
            localDb.requestString(localeValue.key, onTranslation);
        }
    }

    useEffect(initialiseNotification, [localeValue]);

    const initialiseLocalValue = () => {
        setLocaleValue({key:'', value:'', params:null, status: 0});
    }

    useEffect(initialiseLocalValue, [notification]);

    const onTranslation = (key, transValue) =>  {

        if(!transValue){
            console.log("missing translation", key || localeValue.value, transValue);
        }

        //first priority to localevalue, second to the value passed
        //, or set blank to ensure a valid string
        //format the translated string with the params received from the server
        let valueBeforeFormating = transValue || localeValue.value || key || '';

        if(valueBeforeFormating && localeValue.params){
            //format the string
            valueBeforeFormating = formatUnicorn(valueBeforeFormating, localeValue.params);
        }

        setLocaleValue({...localeValue, value: valueBeforeFormating, status: 2});
    }

    //translate only if it is string, else return as it is
    return localeValue.value + ' ' || ' ';
}

export default SarasMsgRow;
