import React from 'react';
import SarasTrans from '../../../library/notifications/SarasTrans';

const Welcome = () => {
    return (
        <div>
            <div><SarasTrans value = "SARAS erp is loading....."/></div>
        </div>
    )
}

export default Welcome;