import { useHistory} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { SIGN_OUT_ROUTE, HOME_ROUTE } from '../main/routeTypes';
import { SET_ERROR } from "./store/actions/types";
import * as uiJS from '../../helper/uiFunctions';
import useSarasStore from '../../library/hooks/localDB/useSarasStore'

const SSOLogin = () => {
    const history = useHistory();
    const dispatchMsg = useDispatch();
    const localDb = useSarasStore();

    // console.log("sso 1", history.location.search.indexOf("isError"));
    const onSaveAppVersion = (result) => {
        // console.log("app version sso", result);
        if(result === 1){
            history.replace(HOME_ROUTE);
        }
        else{
            //if versions are not in correct format then signout
            history.replace(SIGN_OUT_ROUTE);
        }
    }

    if(history.location.search.indexOf("isError") > -1)
    {
        dispatchMsg({type: SET_ERROR, payload: 
            {isError: 1, msgType:1, msg:"Given email is not connected to your user id or invalid user", messageList:{}}});
    }
    else{
        // console.log("app version sso", uiJS.convertStrToKeyValue(history.location.search.substring(1)).appVersion);
        localDb.setAppVersion(uiJS.convertStrToKeyValue(history.location.search.substring(1)).appVersion, onSaveAppVersion);
    }

    return null;
}

export default SSOLogin;
