import React, {useEffect, useState} from 'react';
import useSarasStore from '../../../library/hooks/localDB/useSarasStore'
import SarasNavigation from '../../../library/navigate/SarasNavigation';
import {getPublicMenuImg, smartSearch} from '../../../helper/uiFunctions';
import SarasTrans from '../../../library/notifications/SarasTrans'
import Icon from '../../../library/decor/SarasIcons';
import SarasInput from '../../../library/input/textField/SarasInput';

const GridMenuRow = ({menuRow}) => {

  const renderGridMenuRow = (childRow, index) => {
    if (!childRow.MenuTitle)
      return (null)
  
    return (
      <li key={index}>
        <SarasNavigation menu={childRow} toN={childRow.MenuLink} >
          <img alt="" src={getPublicMenuImg(childRow.MenuIcon)} />
          <span><SarasTrans value={childRow.MenuTitle} /></span>
        </SarasNavigation>
      </li>
    )
  }

  var MIcon = null;

  if(menuRow.MenuIcon.length>0 )
    MIcon = Icon[menuRow.MenuIcon];

    return (
      <>
          <div className='uiMenuGroup'>
            {MIcon ? <MIcon className='uiGridMenuIcon' /> : null}
            <span><SarasTrans value={menuRow.MenuTitle} /></span>
          </div>
          <ul>
            {menuRow.ChildMenus.map((childRow, index) => (
                renderGridMenuRow(childRow, index)
            ))}
          </ul>
      </>
    );
}

const GridMenu = (props) => {
    // const [searchFilter, setSearchFilter] = React.useState("");
    const [appMenus, setAppMenus] = useState([]);
    const [searchMenus, setSearchMenus] = useState(null);
    const localDb = useSarasStore();
  
    const loadLocalMenu = () => {
      if(!appMenus || appMenus.length === 0)
      {
          localDb.getAppMenu(onLocalMenuSuccess);
      }
    };
  
    useEffect(loadLocalMenu, [appMenus]);
  
    const getSearchResult = (filterBy, menus) => {
      
      //set search result to blank if filter param is missing
      if(!filterBy) {
        setSearchMenus(null);
        return;
      }

      let result = [{MenuLink: '', MenuTitle: 'Search Result(s) ' 
        + (filterBy.length <= 2 ? ' starting with ' : ' containing ') 
        + filterBy, MenuIcon: 'search', ChildMenus: []}];
      let ciParam = filterBy.toLowerCase();

      for (var i = 0; i < menus.length; i++) {
        let menu = menus[i];

        if(!menu.MenuType){
          for (var ci = 0; ci < menu.ChildMenus.length; ci++) {
            if (smartSearch(menu.ChildMenus[ci].MenuTitle, ciParam) ||
                smartSearch(menu.ChildMenus[ci].Keyword, ciParam)) {
              result[0].ChildMenus.push(menu.ChildMenus[ci]);
            }
          }
        }
      }
  
      setSearchMenus(result);
    }

    const onLocalMenuSuccess = (key, value) =>  {
      if(value && typeof value != "string"){
        setAppMenus(value);
      }
    }

    const onSearchParamChange = (e, value) => {
      // setSearchFilter(value.trim());
      getSearchResult(value.trim(), appMenus);
    }

  return (
    <>
        <div className="uiGridMenu">
          <div className="uiGridMenuSearch">
            <span className='uiLbl'><SarasTrans value="Search Menu" />:</span>
            <SarasInput ksc="esc" tooltip="Quickly find the menu you need" tooltipAlign="right" autofocus type='input' 
            onSearchAsKeyed={onSearchParamChange}/>
          </div>
          {(searchMenus || appMenus).map((menu, index) => (
              !menu.MenuType ? <GridMenuRow menuRow={menu} key={index}/>
              : null
          ))}
        </div>
    </>
    )
}

export default GridMenu;
