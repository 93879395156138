import React from 'react';
import IconButton from '@material-ui/core/IconButton';

const SarasIconButton =(props)=>{
    return(
        <IconButton 
            color={props.color}
            aria-label={props.label}
            onClick={props.onclick}
            edge={props.edge}
            className={props.className}
            component="span"
        >
            {props.children}
        </IconButton>
    )
}

export default SarasIconButton