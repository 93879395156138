import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

    appBar: {
        zIndex: theme.zIndex.drawer + 1
      }

}));

const SarasAppBar =(props)=>{
    const classes = useStyles();
    return(
    <AppBar position={props.position} className={props.className + ' ' + classes.appBar} style={props.style} >
        <Toolbar style={props.toolbarStyle} className={props.toolbarClass}>
            {props.children}
        </Toolbar>
    </AppBar>
    )
}

export default SarasAppBar