import React from 'react';
import * as fn from '../../../helper/uiFunctions';
import glogo from '../../../logo/google_logo.svg';

const GoogleLogin = (props) => {
const {providers} = props;
// console.log("sso", providers);

    if(providers && providers.indexOf("google") > -1)
    {
        return (
            <form method='POST' action={`${fn.getBaseAPIURL()}sso/google`} >
                <button 
                    className='GoogleSignin'
                    type="submit"
                    value='google'
                    title={`Login using Google`}>
                    <img src={glogo} className="Google-logo" alt="Google Login" />
                    Signin with Google
                </button>
            </form>
        );
    }

    return null;
}

export default GoogleLogin;
