import{useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory} from 'react-router-dom';
import {checkSignInAndAttemptAutoLogin} from './store/actions/action';
import { SIGN_IN_ROUTE, HOME_ROUTE, DOWNLOAD_ROUTE } from '../main/routeTypes';
import {reloadWindow, getReferrerUrl} from '../../helper/uiFunctions';

const CheckSignStatus = () => {

  const isSignedIn = useSelector(state => state.auth.isSignedIn);
  const dispatchLoginCheck = useDispatch();
  const history = useHistory();

  const checkIfSignedIn = () => {
    if(isSignedIn == null)
    {
      // console.log("login 3");

      // console.log("user attempt auto login");
      //send request to server and check login status
      dispatchLoginCheck(checkSignInAndAttemptAutoLogin());
    }
    else if(!isSignedIn && history.location.pathname.indexOf(SIGN_IN_ROUTE) === -1){
      // console.log("login 4");
      //navigate to login page only if it is not already in login page
      history.replace(SIGN_IN_ROUTE);
    }
    else if(isSignedIn){
      // console.log("login 1", window.location, history.location.pathname.indexOf(SIGN_IN_ROUTE));
      // console.log("$hb: user is signed in", history);
      //TODO navigate to home page or previous page (link in referer)
      if(history.location.pathname === "/" || 
        (history.location.pathname && history.location.pathname.indexOf(SIGN_IN_ROUTE) === 0))
      {
        // console.log("login 2", SIGN_IN_ROUTE, history.location.pathname, getReferrerUrl(SIGN_IN_ROUTE, history.location.pathname));

        const referrer = getReferrerUrl(SIGN_IN_ROUTE, history.location.pathname);

        if(referrer){
          history.replace(DOWNLOAD_ROUTE);
          window.location.href = referrer;
        }
        else{
          history.replace(HOME_ROUTE);

          //TODO reloading window is a temporary solution to solve the 
          //problem of menu not getting loaded after first login

          reloadWindow();
        }
      }
    }
  };

  useEffect(checkIfSignedIn, [isSignedIn]);

  // useEffect(() => {
  //   checkIfSignedIn();
  // }, [isSignedIn]);

  return null;
}

export default CheckSignStatus;
