import React,{ useEffect} from 'react'
import SarasHotKeys from '../../notifications/SarasHotKeys';

const SarasInput = (props) => {
    const { onChange, onSearchAsKeyed, ksc, tooltipAlign, value, autofocus, tooltip, placeholder, stayFocused, ...restProps } = props;
    const [txtVal, setTxtVal] = React.useState(value);
    const inputRef = React.useRef();

    const initializeTxtValue = () => {
        setTxtVal(value);
    }

    useEffect(initializeTxtValue,[value]);

    useEffect(()=>{
        // console.log("stayfocused", stayFocused);
        if(autofocus && inputRef.current)
        {
            inputRef.current.focus();
        }
    },[inputRef, autofocus, stayFocused]);

    const onInputBlur = (e) => {
        if(txtVal !== value)
            if(onChange)onChange(e, e.currentTarget.value);
    }

    const onFocus = (e) => {
        if(inputRef.current)
        {
            inputRef.current.select();
        }
    }

    const onHotkeyPress = (e) => {
        if(inputRef.current){
            e.preventDefault();
            inputRef.current.focus();
        }
    }

    const onInputChange = (e) => {
        setTxtVal(e.currentTarget.value);
        if(onSearchAsKeyed) onSearchAsKeyed(e, e.currentTarget.value);
    }

    return (
        <SarasHotKeys ksc={ksc} tooltipAlign={tooltipAlign} onHotKey={onHotkeyPress} tooltip={tooltip}>
            <input ref={inputRef} {...restProps} placeholder={placeholder} onBlur={onInputBlur} onFocusCapture={onFocus} onChange={onInputChange} value={txtVal} />
        </SarasHotKeys>
    )
}

export default SarasInput