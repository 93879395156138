import React from 'react';
import Drawer from '@material-ui/core/Drawer';

const SarasDrawer=(props)=>{
    return(
        <Drawer
        className={props.className}
        variant={props.variant}
        anchor={props.anchor}
        open={props.open}
        classes={props.classes}
        >
            {props.children}
        </Drawer>
    )
}

export default SarasDrawer