import { RESET_ERRORS } from "../actions/types";

const INITIAL_STATE = {
  msgType: 0,
  messageList: {},
  msg: null
}

export default (state = INITIAL_STATE, action) => {
  
  if (action.type === RESET_ERRORS) {
    return INITIAL_STATE;

  } else if (action.payload) {
    const { isError : msgType, msg, messageList } = action.payload;

    return {...state, msgType, messageList, msg};
  }

  return state;
}
