import React, { useEffect } from 'react';
import hotkeys from 'hotkeys-js';
import SarasTooltip from "./SarasTooltip";

//TODO support container concept for hotkeys 
//so that shortcut keys within an active container are enabled
//till then enableshort key prop is false for saras tabs
import {getCurrentLayer} from '../../view/cmn/docAutomation/formFunctions';


const keyMap = {
    home :"ctrl+shift+h",
    save :"ctrl+shift+s",
    search:"alt+s",
    print:"ctrl+shift+p",
    cancel:"ctrl+esc",
    enter:"enter"
}

const toolTipMap = {
    home :"ctrl + shift + h",
    save :"ctrl + shift + s",
    search:"alt + s",
    print:"ctrl + shift + p",
    cancel:"ctrl + esc",
    enter:"enter"
}

const SarasHotKeys = (props) => {
    const {ksc, tooltipAlign, onHotKey, tooltip, children} = props;
    // const [hotkeyLayer, setHotkeyLayer] = React.useState();

    //this will fire only when the hotkey scope matches the current scope
    const onHKTrigger = (e) => {
        if(onHotKey) onHotKey(e);
    }

    hotkeys.filter = function(event){
        if(!event || !event.code || !event.target) return false;
        
        var target = event.target;
        var tagName = target.tagName;
        
        var editableWOEnter = (tagName === 'INPUT' || tagName === 'SELECT');
        var editableWithEnter = (tagName === 'TEXTAREA');

        var keySupportInput = (event.code.toLowerCase() === 'enter' 
            || event.code.toLowerCase() === 'escape');
        
        var specialKey = (event.ctrlKey || event.shiftKey);

        // console.log("hotkeys", event, (editableWOEnter || editableWithEnter) && keySupportInput, specialKey);

        //enable shortcut in following scenario
        //1: input is not active then enable all shortcut
        //2: if a special key is being pressed
        //3: if a key that support input is pressed
        if(!(editableWOEnter || editableWithEnter) 
            || specialKey || keySupportInput) return true;

        return false;
    }

    const initialiseLayer = () => {

        const currentLayer = getCurrentLayer();

        if(ksc){
            const customKey = keyMap[ksc] || ksc;
            
            hotkeys.unbind(`${customKey}`, `scope_${currentLayer}`);
            hotkeys(`${customKey}`, `scope_${currentLayer}`, onHKTrigger);
            
            // setHotkeyLayer(currentLayer);
        }

        return function () {
            if(ksc){
                const customKey = keyMap[ksc] || ksc;

                hotkeys.unbind(`${customKey}`, `scope_${currentLayer}`);
            }
        }
    }

    useEffect(initialiseLayer, [ksc, onHotKey, children]);

    if(!ksc)
    {
        return (
            <SarasTooltip tooltipAlign={tooltipAlign} title={tooltip ? tooltip : ""}>
                {children}
            </SarasTooltip>
        )
    }
    else
    {
        //ksc can be a key pointing to a map containing the shortcut or it can be a shortcut
        //eg: save is a key pointing to ctrl+s, whereas 'h' is a direct shortcut
        const tooltipKey = toolTipMap[ksc] || ksc;

        return (
            <SarasTooltip tooltipAlign={tooltipAlign} title={<div>{tooltip ? tooltip : ""} {tooltip ? <br/> : <></>} Keyboard shortcut: {tooltipKey}</div>}>
                {children}
            </SarasTooltip>
        )
    }
}

export default SarasHotKeys;
