import React, {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import SarasButton from '../../library/input/buttons/button/SarasButton';
import SarasCssBaseline from '../../library/decor/cssBaseline/SarasCssBaseline'
import SarasTextField from '../../library/input/textField/SarasTextField'
import SarasLink from '../../library/input/link/SarasLink'
import SarasBox from '../../library/container/box/SarasBox';
import SarasTypography from '../../library/decor/typography/SarasTypography' 
import SarasMakeStyles from '../../library/decor/makeStyles/SarasMakeStyles';
import SarasContainer from '../../library/container/SarasContainer'
import {attemptSignIn} from './store/actions/action';
import CheckSignStatus from './CheckSignStatus';
import logo from '../../logo/app_logo_s.svg';
import './SignIn.scss';
import SarasTrans from '../../library/notifications/SarasTrans';
import GoogleLogin from './sso/GoogleLogin';
import SarasErpApi from '../../helper/SarasErpApi';

function Copyright() {
  return (
    <SarasTypography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <SarasLink color="inherit" target="_blank" href="https://sarasennovations.com" text="SARAS ennovations">
      </SarasLink>{' '}
      {new Date().getFullYear()}
      {'.'}
    </SarasTypography>
  );
}

const useStyles = SarasMakeStyles(theme => ({
  wallpaper:{
    backgroundColor: '#dedede'
  },
  paper: {
    marginTop: theme.spacing(10),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignIn = () => {
    const classes = useStyles();

    const [email, setEmail] = useState('');
    const [pass, setPassword] = useState('');
    const [providers, setProviders] = useState(null);

    const dispatchLogin = useDispatch();
    
    const onEmailChange = (e) => {
      setEmail(e.target.value);
    }

    const onPassChange = (e) => {
      setPassword(e.target.value);
    }

    const onSubmit = (event) => {
      event.preventDefault();

      // call action reducer
      dispatchLogin(attemptSignIn(email, pass));
    }

    const initialisePath = () => {
      if(providers == null){
          //request for providers from the server, if none are available then expect "na"
          SarasErpApi.get('sso/getproviders').then((response) => {
            // console.log("sso", response && response.data);
            setProviders((response && response.data) || "na");
        });
      }
  };
  
  useEffect(initialisePath, [providers]);

      return (
        <React.Fragment>
            <CheckSignStatus />
            <div className={classes.wallpaper}>
              <SarasContainer maxWidth="xs">
                <SarasCssBaseline />
                <div className={classes.paper}>
                  <div container="true" className="logo-container">
                      <img src={logo} className="App-logo" alt="SARAS erp" />
                      <SarasTypography component="h1" variant="h5"><SarasTrans value = "Sign in"/></SarasTypography>
                  </div>
                  <form onSubmit={onSubmit} noValidate className={classes.form}>
                    <SarasTextField variant="outlined" margin="normal" required fullWidth id="loginid" label="Login ID" name="loginid"
                      autoComplete="loginid" autoFocus value={email} onChange={onEmailChange} />
                    <SarasTextField variant="outlined" margin="normal" required fullWidth name="password" label="Password" type="password"
                      id="password" autoComplete="current-password" value={pass} onChange={onPassChange}/>
                    <SarasButton type="submit" fullWidth variant="contained" color="primary" className={classes.submit} text='Sign In'/>
                  </form>
                  <GoogleLogin providers={providers}/>
                </div>
                <SarasBox mt={8}>
                  <Copyright />
                </SarasBox>
              </SarasContainer>
            </div>
          </React.Fragment>
      );
  }

export default SignIn;