import React from 'react';
import CheckSignStatus from '../../user/CheckSignStatus';

const MainContentArea = (props)=>{
    
    return(
        <div className='uiAppPaper' style={{flexGrow: '1', marginBottom:'50px'}}>
            <div className='uiAppContentStart'></div>
            <div className="uiAppContent">
                <CheckSignStatus />
                {props.children}
            </div>
        </div>
    );

}

export default MainContentArea