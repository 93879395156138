import React, {useEffect, useState, useRef} from 'react';
import { NavLink } from "react-router-dom";
import SarasMakeStyles from '../../../library/decor/makeStyles/SarasMakeStyles'
import SarasCssBaseline from '../../../library/decor/cssBaseline/SarasCssBaseline.js';
import SarasDrawer from '../../../library/container/drawer/SarasDrawer';
import SarasList from '../../../library/repeater/list/SarasList';
import SarasListItem from '../../../library/repeater/list/SarasListItem';
import AppMenuRow from './AppMenuRow';
import {HomeIcon, IconLeftArrow} from '../../../library/decor/SarasIcons';
import useSarasStore from '../../../library/hooks/localDB/useSarasStore'
import SarasTrans from '../../../library/notifications/SarasTrans'
import './AppMenu.scss';

const AppMenu = (props) => {
  const classes = UseStyles();
  const accordianRef = useRef();
  const localDb = useSarasStore();

  const [appMenus, setAppMenus] = useState([]);

  const checkIfMenuRequired = () => {
    if(!appMenus || appMenus.length === 0)
    {
        localDb.getAppMenu(onLocalMenuSuccess);
    }
  };

  useEffect(checkIfMenuRequired, [appMenus]);

  const onLocalMenuSuccess = (key, value) =>  {
    //this is required since in previous version menu was 
    //stored as a string and user will face issue in first login
    if(value && typeof value != "string"){
      setAppMenus(value);
    }
}

// console.log("app menu", appMenus, props.isMenuOpen);

  const closeAppMenu =()=>{
    props.onClose();
  }

  if(!appMenus) {
    return (
      null
    )
  }

  return (
    <div ref={accordianRef}>
    <div className={classes.root} onMouseLeave={closeAppMenu} >
      <SarasCssBaseline/>
      <SarasDrawer
        className={classes.drawer}
        variant="persistent"
        anchor="left"
        open={props.isMenuOpen}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        { /* <div id="style-1" className="scrollbar"> */}
        <SarasList component="nav" disablePadding style={{marginTop:'35px',marginBottom: '35px', width: '100%'}} >
          <NavLink to="/home" key="saras-home">
            <SarasListItem ListIcon={HomeIcon} iconClass='uiAppIcon' 
              text={<SarasTrans value="Home" />}>
            </SarasListItem>
          </NavLink>
          {appMenus.map((menu, index) => (
            !menu.MenuType ? <AppMenuRow menuRow={menu} key={index} handleMenuClose={closeAppMenu} />
            : null
          ))}
          <div onClick={closeAppMenu} style={{backgroundColor:'#e4e4e4',height:'45px',position:'fixed',bottom:'33px',width: '239px',cursor: 'pointer'}}>
            <IconLeftArrow style={{margin:'11px',color:'#737171'}}/><span style={{margin :'14px',position: 'relative',top: '-17px'}}><SarasTrans value="Hide Menu" /></span>
          </div>
        </SarasList>
        { /* </div> */}
      </SarasDrawer>
    </div>
    </div>
  );
}

const DRAWER_WIDTH = 240;

const UseStyles = SarasMakeStyles(theme => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: DRAWER_WIDTH,
    flexShrink: 0,
  },
  drawerPaper: {
    width: DRAWER_WIDTH,
  }
}));

export default AppMenu;
