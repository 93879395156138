import React, { useRef } from 'react';
import Button from '@material-ui/core/Button';
import SarasHotKeys from '../../../notifications/SarasHotKeys'

const SarasButton=(props)=>{
    const { id,disabled,onClick,className,style,text,variant,fullWidth,actionv, ksc } = props
    const btn = useRef();

    const onHotkeyPress = (e) => {
        btn.current.click();
    }

    return(
        <SarasHotKeys ksc={ksc} onHotKey={onHotkeyPress} >
            <Button ref={btn} type={props.type} id={id} disabled={disabled} fullWidth={fullWidth} variant={variant || 'contained'} 
                color={props.color} className={className} onClick={onClick} style={style} actionv={actionv} ksc={ksc} >
                {ksc?`${text} (${ksc})`:text}
            </Button>
        </SarasHotKeys>
    )
}

export default SarasButton