import React from 'react';
import Container from '@material-ui/core/Container';

const SarasContainer = (props)=>{
    return(
        <Container component={props.component} maxWidth={props.maxWidth}>
            {props.children}
        </Container>
    )
}

export default SarasContainer