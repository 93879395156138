import Dexie from 'dexie';

//for api reference visit dexie library https://dexie.org/docs/API-Reference#quick-reference

const db = new Dexie('SarasDb');

db.version(1).stores({
    gn: "key",
    locale: "key"
});

export default db;