import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import reduxThunk from 'redux-thunk';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {combineReducers} from 'redux';
import AuthReducer from './layout/user/store/reducers/index';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const reducers = combineReducers({...AuthReducer})

//uncomment for development
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

//uncomment for production
const composeEnhancers = compose;

const store = createStore(
    reducers, 
    composeEnhancers(applyMiddleware(reduxThunk))
    );

    const theme = createMuiTheme({
        overrides: {
          MuiTooltip: {
              tooltip: {
                  fontSize: "12px",
              },
          },
      },
        typography: {
          fontFamily: [
            'Open Sans',
            'Arial',
            'Sans-Serif'
          ].join(','),
        },
        fontSize: '11px'
      });

ReactDOM.render(
    <Provider store={store}>
        <ThemeProvider theme={theme}>
            <App />
        </ThemeProvider>
    </Provider>
, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();


