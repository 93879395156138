import React from 'react';
import TextField from '@material-ui/core/TextField';

const SarasTextField =(props)=>{
    return(
        <TextField {...props.autocompleteParams} variant={props.variant} margin={props.margin} required={props.required} fullWidth={props.fullWidth} id={props.id}
        label={props.label} type={props.type} name={props.name} autoComplete={props.autoComplete} autoFocus={props.autoFocus} value={props.value} onChange={props.onChange} onClick={props.onClick}
        size={props.size} className={props.className}
        />
    )
}

export default SarasTextField