import { useDispatch } from 'react-redux';
import {signOut} from './store/actions/action';


const SignOut = () => {
    const dispatchSignOut = useDispatch();

    const signOutUser = (event) => {
      dispatchSignOut(signOut());
    }

    signOutUser();

    return(null)
  }

export default SignOut;