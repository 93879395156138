import React, {useEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import useFormApi from '../../../view/cmn/docAutomation/useFormApi'
import CheckSignStatus from '../../user/CheckSignStatus';
import SarasAppBar from '../../../library/container/appBar/SarasAppBar';
import SarasTrans from '../../../library/notifications/SarasTrans';
import SarasIconButton from '../../../library/input/buttons/iconButton/SarasIconButton';
import UserCulture from '../../user/components/UserCulture';
import CompanySwitcher from '../../user/components/CompanySwitcher';
import UserNavigation from '../../user/components/UserNavigation';
import useSarasStore from '../../../library/hooks/localDB/useSarasStore'
import SarasPopup from '../../../library/container/popup/SarasPopup';
import logo from '../../../logo/app_logo_p.svg';
import AppMenu from './AppMenu';
import ShortcutMenu from './ShortcutMenu';
import {IconHelp} from '../../../library/decor/SarasIcons';
import * as fn from "../../../helper/uiFunctions";
import { ROOT_ROUTE, HOME_ROUTE, SIGN_OUT_ROUTE } from '../../main/routeTypes';

const PageHeader = (props)=> {
  const [isMenuAvailable, setIsMenuAvailable] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [popupName, setPopupName] = useState("");
  const isSignedIn = useSelector(state => state.auth.isSignedIn);
  const serverAttributes = useSelector(state => state.auth.server);

  const history = useHistory();
  const formApi = useFormApi();
  const localDb = useSarasStore();

  const frbRef = React.useRef(null);
  const ipkRef = React.useRef(null);
  const dmRef = React.useRef(null);
  const debugRef = React.useRef(null);
  const maRef = React.useRef(null);

  const checkIfVersionChanged = () => {
    localDb.getAppVersion(onLocalVersionSuccess);
  };

  useEffect(checkIfVersionChanged, [serverAttributes]);

  const onLocalVersionSuccess = (key, value) =>  {
      if(fn.versionChanged(value, serverAttributes.AppVersion, 3) === 1) {
        // console.log("app version signout", history.location.pathname);
        if(history.location.pathname !== SIGN_OUT_ROUTE){
          history.push(SIGN_OUT_ROUTE);
        }
      }
  }

  const checkIfMenuRequired = () => {
    if(!isMenuAvailable && isSignedIn) {
      localDb.getAppMenu(onLocalMenuSuccess);
    }
  };

  useEffect(checkIfMenuRequired, [isMenuAvailable, isSignedIn]);

  const onLocalMenuSuccess = (key, value) =>  {
    if(value && typeof value != "string"){
      setIsMenuAvailable(true);
    }
    else{
      let menuAction = { actionId: "get", actionName: "menu", actionParam: {'content-type':'app-menu'}, containerKey:""};
      formApi.runGenericAction(menuAction, "menu", null, null, null, "", "", 0, onMenuSuccess);
    }
}

const onMenuSuccess = (action, containerKey, rowKey, headerKey, response) => {
    // console.log("menu response", response, history);

    if(response.dataList && response.dataList.menu){
      const menusSerialised = response.dataList.menu;

      if(menusSerialised){
        setIsMenuAvailable(true);
        localDb.setAppMenu(JSON.parse(menusSerialised));
      }

      //goto default link but only when there is no other active link
      if(response.dataList.defaultLink && 
      (history.location.pathname === "" 
      || history.location.pathname === ROOT_ROUTE
      || history.location.pathname === HOME_ROUTE))
      {
        history.push(response.dataList.defaultLink);
      }
    }
  }

  const handleAppMenuOpen = () => {
    setIsMenuOpen(true);
  };

  const handleAppMenuClose = () => {
    setIsMenuOpen(false);
  };

  const showHidePopup = (popupKey) => {
    setPopupName(popupKey);
  }

  const renderPopup = () => {
    let popupRef = null;
    let popupData = null;

    if (popupName === "MaintenanceModeFlag"){
      popupRef = maRef;
      popupData = serverAttributes.MaintenanceModeDescription;
    }
    else if (popupName === "DebugModeFlag"){
      popupRef = debugRef;
      popupData = serverAttributes.DebugModeFlagDescription;
    }
    else if (popupName === "DeveloperModeFlag"){
      popupRef = dmRef;
      popupData = serverAttributes.DeveloperModeFlagDescription;
    }
    else if (popupName === "InvalidPrivateKeyFlag"){
      popupRef = ipkRef;
      popupData = serverAttributes.InvalidPrivateKeyFlagDescription;
    }
    else if (popupName === "ForceRollbackFlag"){
      popupRef = frbRef;
      popupData = serverAttributes.ForceRollbackFlagDescription;
    }

    if(popupData){
      return (
        <SarasPopup anchorRef={popupRef} open={true}>
            <div className="uiBox" style={{maxWidth:"300px"}}>
                <ul>
                  {
                    Object.values(popupData).map((row, index)=>(
                        <li style={{listStyle:"disk", listStylePosition:"inside"}} key={index}>
                            {row}
                        </li>
                    ))
                  }
                </ul>
            </div>
        </SarasPopup>
      )
    }

    return null;
  }

  const renderMAMode = () => {
      return (
        <>
          {serverAttributes.ForceRollbackFlag === 1 ? 
            <div 
              ref={frbRef}
              className='uiAppWarningTag'
              onMouseEnter={() => {showHidePopup("ForceRollbackFlag")}}
              onMouseLeave={() => {showHidePopup("")}}
              >
                <SarasTrans trKey="ForceRollbackFlag" />
            </div> 
          : null}
          {serverAttributes.InvalidPrivateKeyFlag === 1 ? 
            <div 
              ref={ipkRef}
              className='uiAppWarningTag'
              onMouseEnter={() => {showHidePopup("InvalidPrivateKeyFlag")}}
              onMouseLeave={() => {showHidePopup("")}}
              >
                <SarasTrans trKey="InvalidPrivateKeyFlag" />
            </div> 
          : null}
          {serverAttributes.DeveloperModeFlag === 1 ? 
            <div 
              ref={dmRef}
              className='uiAppWarningTag'
              onMouseEnter={() => {showHidePopup("DeveloperModeFlag")}}
              onMouseLeave={() => {showHidePopup("")}}
              >
                <SarasTrans trKey="DeveloperModeFlag" />
            </div> 
          : null}
          {serverAttributes.DebugModeFlag === 1 ? 
            <div 
              ref={debugRef}
              className='uiAppWarningTag'
              onMouseEnter={() => {showHidePopup("DebugModeFlag")}}
              onMouseLeave={() => {showHidePopup("")}}
              >
                <SarasTrans trKey="DebugModeFlag" />
            </div> 
          : null}
          {serverAttributes.MaintenanceModeFlag === 1 ? 
            <div 
              ref={maRef}
              className='uiAppWarningTag'
              onMouseEnter={() => {showHidePopup("MaintenanceModeFlag")}}
              onMouseLeave={() => {showHidePopup("")}}
              >
                <SarasTrans trKey="MaintenanceModeFlag" />
            </div> 
          : null}

          {serverAttributes.ProfileName ?
            <div className={`uiServerProfile uiSP${serverAttributes.ProfileName}`}>
              <div className="uiSPInd"></div>
              <div className="uiSPName">{`${serverAttributes.ProfileName}`}</div>
            </div>
          : null
          }
          {renderPopup()}
        </>
      );
  }
  
  return (
    <div onMouseLeave={handleAppMenuClose} >
      <SarasAppBar position="fixed" style={{ zIndex:'1201' }} className="uiAppHeader" toolbarClass="uiAppToolBar">
          <div onMouseOver={handleAppMenuOpen}>
          <SarasIconButton
            color="inherit"
            aria-label="SARAS erp menu"
            className="uiAppMenuIcon"
            onclick={isMenuOpen ? handleAppMenuClose : handleAppMenuOpen}
            edge="start"
          >
            <img src={logo} className="" alt="logo" />
          </SarasIconButton>
          </div>
          <h3 onMouseOver={handleAppMenuOpen} onClick={isMenuOpen ? handleAppMenuClose : handleAppMenuOpen} style={{cursor: 'pointer'}}>
            <SarasTrans value="Menu" />
          </h3>
          <ShortcutMenu />
          <div style={{ float: 'right', display: 'flex', position: 'absolute', right: '15px',height: '23px',marginTop: 'auto',marginBottom: 'auto' }}>
            {renderMAMode()}
            <a href="http://docs.saraserp.com/doku.php/erp-manual/start" target='#' style={{color:'white',textDecoration:'none'}}>
              <span className='uiAppIconWithLink'>
                <IconHelp className='uiAppIcon'/>
                <span><SarasTrans value="Help" /></span>
              </span>
            </a>
            <UserCulture/>
            <CompanySwitcher currentPath = {props.currentPath}/>
            <UserNavigation />
            {/* <UserMessages/> */}
            <CheckSignStatus />
          </div>
      </SarasAppBar>
      <AppMenu isMenuOpen={isMenuOpen} onClose={handleAppMenuClose} />
    </div>
  );
}

export default PageHeader