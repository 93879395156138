import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';


const SarasTooltip = (props) => {
    const {title, tooltipAlign, children} = props;

    if(!title)
    {
        return (
            children
        )
    }
    else
    {
        // <Tooltip title={<div className='uiTooltip'>{title}</div>} arrow interactive>
        // <Tooltip title={title} arrow interactive>
        return (
            <Tooltip title={title || ""} arrow placement={tooltipAlign || "bottom"}>
            {/* <Tooltip title={<div className='uiTooltip'>{title}</div>} arrow interactive> */}
                {children}
            </Tooltip>
        )
    }
}

export default SarasTooltip;
