import React from 'react';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';

const SarasListItem = (props)=>{
    const Icon = props.ListIcon;
    return(
        <div key={props.index}>
        <ListItem style={props.listItemStyle} onClick={props.onclick}>
            {props.children}
            {!!Icon &&(
            <ListItemIcon className={props.listIconClass} style={props.listIconStyle}>
                <Icon className={props.iconClass} style={props.iconStyle}/>
            </ListItemIcon>
            )}
            {
                !!props.text &&(<ListItemText style={props.listItemTextStyle}>{props.text}</ListItemText>)
            }
            {props.executeBeforeEnd}
            
        </ListItem>
        
        {props.divider?<Divider variant="middle" />:''}
        </div>
    )
}

export default SarasListItem
