import React from 'react';
import Collapse from "@material-ui/core/Collapse";

const SarasCollapse = (props)=>{
    return(
        <Collapse in={props.in} className={props.className} style={props.style}>
            {props.children}
        </Collapse>
    )
}

export default SarasCollapse