import React from 'react'
import SarasTrans from '../../notifications/SarasTrans'

const SarasMenuItem =(props)=>{
    const {viewData} = props

// console.log(Object.values(viewData)[0]);

    return (
        <span><SarasTrans value={Object.values(viewData)[0]} /></span>
    )
}

export default SarasMenuItem