import {useState, useEffect} from 'react';
import useSarasStore from '../hooks/localDB/useSarasStore';

//translate to show any string according to the language selected
//ensure this recieves the following:
//      all string used directly in react components 
//      and those coming from server as json view labels
const SarasTrans = (props)=>{
    const {trKey, value} = props;
    const [localeValue, setLocaleValue] = useState(value);

    const localDb = useSarasStore();

    const getTranslatedValue = () => {
        if(trKey || value){
            if(typeof (trKey || value) == "string"){
                //if key is not available then translate using value
                localDb.requestString(trKey || value, onTranslation);
            }
        }
    }

    useEffect(getTranslatedValue, [trKey, value]);

    const onTranslation = (trKey, transValue) =>  {
        if(!transValue){
            console.log("missing translation", trKey || value, transValue);
        }

        //first priority to localevalue, second to the value passed, or set blank to ensure a valid string
        setLocaleValue(transValue || value || trKey || '');
    }

    //translate only if it is string, else return as it is
    return localeValue || '';
}

export default SarasTrans