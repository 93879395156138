import React,{useEffect, useState} from 'react';
import SarasModal from '../../library/container/popup/SarasModal';
import SarasButton from '../../library/input/buttons/button/SarasButton2';
import SarasTrans from './SarasTrans';

const SarasConfirmPrompt = (props) => {
    const { promptData } = props
    const {message, actionName, callback, ...restProps} = promptData
    const [open, setOpen] = useState(false);
    
    useEffect(() => {
        if (promptData) {
            if (promptData.message && promptData.message.length > 0) {
                setOpen(true);
            }
        }
    }, [promptData]);

    const onModalClose = (e) => {
        setOpen(false);
    }

    const onAction = () => {
        if(callback)callback({...restProps});
        setOpen(false);
    }

    const onCancel = () => {
        setOpen(false);
    }

    if(!open)
        return(null);

    return(
        <SarasModal enableModal={open} onModalClose={onModalClose} modelState={open} >
            <div className=" phViewArea" style={{"background": "#fff","boxSizing": "border-box","padding": "2px","width": "398px"}}>
                <div className="phViewHeading">
                    <div className="phTitle">
                        <span><SarasTrans value="Approve Action" /></span>
                    </div>
                </div>
                <div className="phPopupContent" style={{"display": "block", "width":"auto", "minHeight":"0px", "maxHeight": "none", "height": "108px"}}>
                <SarasTrans value={message} />
                </div>
                <div className="uiActionSection" style={{clear: "both"}}>
                    <div className="phAction" style={{float: "right"}}>
                        <SarasButton ksc="enter" className="uiYesAction" style={{margin: "5px 5px"}} onClick={onAction}>Yes, {actionName || 'Proceed'}</SarasButton>
                        <SarasButton ksc="esc" className="uiCancelAction" style={{margin: "5px 5px"}} onClick={onCancel}>Go Back</SarasButton>
                    </div>
                    <div style={{clear: "both"}} />
                </div>
            </div>
        </SarasModal>
    )
}

export default SarasConfirmPrompt