import React, {lazy} from 'react';
import {Route} from 'react-router-dom';
import DisplayMessages from '../../library/notifications/DisplayMessages';
import CheckSignStatus from '../user/CheckSignStatus';
import { ROOT_ROUTE, DOWNLOAD_ROUTE, SSO_ROUTE, SIGN_IN_ROUTE_WITH_GOTO, SIGN_OUT_ROUTE, HOME_ROUTE, MODULE_ROUTE } from './routeTypes';
import { Switch } from 'react-router-dom';
import RouteWithLayout from './RouteWithLayout';
import MasterPage from './MasterPage'
import Welcome from './components/Welcome';
import DownloadPage from './components/Download';

import SSOLogin from '../user/SSOLogin';
import SignIn from '../user/SignIn';
import SignOut from '../user/SignOut';
import Home from './components/Home';

const ModuleLauncher = lazy(() => import('../../view/cmn/docAutomation/ModuleLauncher'));

const SARASRoutes = (props) => {
  return (
    <>
    <CheckSignStatus />
    <DisplayMessages />
    <Switch>
      <Route path={ROOT_ROUTE} exact component={Welcome}/>
      <Route path={DOWNLOAD_ROUTE} exact component={DownloadPage}/>
      <Route path={SSO_ROUTE} exact component={SSOLogin} />
      <Route path={SIGN_IN_ROUTE_WITH_GOTO} component={SignIn} />
      <Route path={SIGN_OUT_ROUTE} exact component={SignOut} />

      <RouteWithLayout component={Home} exact layout={MasterPage} path={HOME_ROUTE} />
      <RouteWithLayout component={ModuleLauncher} exact layout={MasterPage} path={MODULE_ROUTE} />
    </Switch>
    </>
  );
}

export default SARASRoutes;
