import React, {useEffect} from 'react'
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

const SarasModal = (props) => {
    const { enableModal, children, onModalClose, modelState } = props
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();

    const initModalState = () => {
        setOpen(modelState);
    }

    useEffect(initModalState, [modelState]);

    const handleClose = (e, reason) => {
        setOpen(false);
        if(onModalClose) onModalClose(e, reason);
    };

    if(enableModal){
        return (
            <Modal aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description" className={classes.modal}
                open={open} onClose={handleClose} 
                closeAfterTransition disableEscapeKeyDown disableBackdropClick
            >
                    {
                        children
                    }
            </Modal>
        )
    }
    else{
        return(
            children
        )
    }
}

export default SarasModal