import React, {useState} from 'react';
import { useHistory } from 'react-router-dom';
import SarasMenu from '../../../library/repeater/menu/SarasMenu';
import {StarIcon} from '../../../library/decor/SarasIcons';
import useSarasStore from '../../../library/hooks/localDB/useSarasStore'
import UserFavouriteMenuRow from './UserFavouriteMenuRow'
import SarasTrans from '../../../library/notifications/SarasTrans'

const UserFavouriteMenu = (props) => {
    const [favMenuChangeCount, setFavMenuChangeCount] = useState(0);
    const [favMenu, setFavMenu] = useState([]);
    const localDb = useSarasStore();
    let history = useHistory();

    const onMenuChangeSuccess = (key, value) =>  {
      //load menu only if the menu has changed
      if(value > favMenuChangeCount){
        // console.log('Test menu');
        setFavMenuChangeCount(value);
        localDb.getAppMenu(onLocalMenuSuccess);
      }
    }

    localDb.getTopMenuChangeCount(onMenuChangeSuccess);

    const onLocalMenuSuccess = (key, value) =>  {
      if(value && typeof value != "string"){
        
        //extract and set only fav menu
        //if fav menu not found, then add it in the menu in local store
        // console.log('Test menu success');
        
        let searchTopMenu = value.filter(
            function(value){ return value.MenuType === "f" }
          );
          
          // console.log('top menu fav', value, searchTopMenu);
            
        if(searchTopMenu && searchTopMenu.length > 0){
          setFavMenu(searchTopMenu[0].ChildMenus.sort((a, b) => b.UseCount - a.UseCount).splice(0, 5));
        }
        else{
          setFavMenu([]);
        }
      }
    }
    
    const getSelectedRow = (value) => {
        // console.log("report value", value);
        if(value){
            // let selectedItem = viewData.rowValues[value];
            // if(!selectedItem)
            let selectedItem = favMenu[value];
            // console.log("report item", Object.keys(viewData.rowValues)[value], selectedItem);
            return selectedItem;
        }
    }

    const onMenuItemClick = (e, value) => {
        let selectedItem = getSelectedRow(value);

        if(selectedItem){
          history.push(selectedItem.MenuLink);
        }
    }

    const renderFavMenu = () => {
        return (
            <span className='uiAppIconWithLink'>
                <StarIcon className='uiAppIcon'/>
                <span><SarasTrans value="Top Menu" /></span>
            </span>
        )
      }
  
    return (
        <SarasMenu menuTrigger={renderFavMenu()} 
            dataList={favMenu} hideTriggerIfNoOptions={1}
            menuItem={UserFavouriteMenuRow} onSelection={onMenuItemClick}></SarasMenu>
    )
}

export default UserFavouriteMenu